import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Sms from './Sms';

const SmsWrapper = ({ projectId }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.SMS}`}
        render={() => (
          <Redirect
            to={`${ROUTES?.PROJECTS}/${projectId}${ROUTES?.SMS}${ROUTES?.ANALYTICS}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.SMS}/:id`}
        component={Sms}
      />
    </Switch>
  );
};

export default SmsWrapper;
