import { gql } from '@apollo/client';

export const CREATE_TEMPLATE = gql`
  mutation createTemplate($data: TemplateData!) {
    createTemplate(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($data: TemplateUpdateData!, $id: ID!) {
    updateTemplate(data: $data, id: $id) {
      message
      status
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(id: $id) {
      status
      message
    }
  }
`;

export const COPY_TEMPLATES = gql`
  mutation CopyTemplates($data: CopyTemplatesData!) {
    copyTemplates(data: $data) {
      message
    }
  }
`;
