import { Card, Tabs } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import InviteTeamMembersList from '../../members/components/InviteTeamMembersList';
import ProjectList from '../../projects/components/ProjectList';

const { TabPane } = Tabs;
const LandingTab = ({ defaultActiveKey, isFirstTab = true }) => {
  const history = useHistory();

  return (
    <Card className="full-height-card landing-card">
      <Tabs
        className={
          isFirstTab
            ? 'tabs no-border-top-left-radius without-sub-header-content'
            : 'tabs border-top-left-radius without-sub-header-content'
        }
        type="card"
        onChange={(e) => {
          history?.push(`/${e}`);
        }}
        defaultActiveKey={defaultActiveKey || 'landing'}
      >
        <TabPane tab="Projects" key="landing">
          <ProjectList />
        </TabPane>
        <TabPane tab="Teams" key="teams">
          <InviteTeamMembersList />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default LandingTab;
