import { gql } from '@apollo/client';

export const CREATE_PLATFORM = gql`
  mutation createPlatform($data: PlatformData!) {
    createPlatform(data: $data) {
      message
      status
      data {
        id
        name
      }
    }
  }
`;

export const CREATE_PROVIDER = gql`
  mutation createProvider($data: ProviderData!) {
    createProvider(data: $data) {
      message
      status
      data {
        id
      }
    }
  }
`;

export const UPDATE_PLATFORM = gql`
  mutation updatePlatform($data: PlatformUpdateData!, $id: ID!) {
    updatePlatform(data: $data, id: $id) {
      message
      status
      data {
        id
        name
        platformConfigs
      }
    }
  }
`;

export const DELETE_PLATFORM = gql`
  mutation deletePlatform($id: ID!) {
    deletePlatform(id: $id) {
      status
      message
    }
  }
`;
