import React from 'react';
import './authdetails.less';
import AuthContent from './components/AuthContent';

const AuthDetail = ({ activeKey }) => {
  return (
    <div className="auth-details">
      <AuthContent activeKey={activeKey} />
    </div>
  );
};

export default AuthDetail;
