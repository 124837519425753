import React from 'react';
import EmailTabs from './components/EmailTabs';

const Email = ({ setActiveKey, activeKey }) => {
  return (
    <>
      <h1 className="title ml-30 mt-20">E-mail</h1>
      <EmailTabs setActiveKey={setActiveKey} activeKey={activeKey} />
    </>
  );
};

export default Email;
