import { gql } from '@apollo/client';

export const PROJECT = gql`
  query project($id: ID!) {
    project(id: $id) {
      id
      name
      description
      logo
      projectsMember {
        id
        email
      }
    }
  }
`;

export const PROJECT_LISTING = gql`
  query projects($filter: ProjectFilter!) {
    projects(filter: $filter) {
      projects {
        id
        name
        description
        logo
        createdBy
      }
    }
  }
`;

export const PROJECT_ENVIRONMENT = gql`
  query projectEnvironment($projectId: ID!) {
    projectEnvironment(projectId: $projectId) {
      count
      environments {
        id
        environment
        projectId
        createdBy
        permission
      }
    }
  }
`;
