import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import '../../email/email.less';

const { TabPane } = Tabs;

const SmsTabs = () => {
  const [activeKey, setActiveKey] = useState('analytics');
  const [isFirstTab, setIsFirstTab] = useState(false);
  const { projectId, id } = useParams();
  const history = useHistory();

  const handleKeyComponent = (keyPath) => {
    history?.push(`${ROUTES?.PROJECTS}/${projectId}${ROUTES?.SMS}/${keyPath}`);
    if (keyPath === 'analytics') {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
  };

  useEffect(() => {
    setActiveKey(id);
    if (id === 'analytics') {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card className="full-height-card">
      <Tabs
        className={`${
          // eslint-disable-next-line no-nested-ternary
          isFirstTab ? 'tabs no-border-top-left-radius' : 'tabs'
        }`}
        type="card"
        activeKey={activeKey}
        onChange={(e) => {
          handleKeyComponent(e);
        }}
      >
        <TabPane tab="Analytics" key="analytics">
          <div className="tab-content-block">{/* <Analytics /> */}</div>
        </TabPane>
        <TabPane tab="Template" key="template">
          <div className="tab-content-block">{/* <Template /> */}</div>
        </TabPane>
        <TabPane tab="Senders" key="senders">
          <div className="tab-content-block">{/* <Senders /> */}</div>
        </TabPane>
        <TabPane tab="Channels" key="channels">
          <div className="tab-content-block">{/* <Channels /> */}</div>
        </TabPane>
        <TabPane tab="Logs" key="logs">
          <div className="tab-content-block">{/* <Logs /> */}</div>
        </TabPane>
        <TabPane tab="Setting" key="setting">
          <div className="tab-content-block">{/* <Setting /> */}</div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default SmsTabs;
