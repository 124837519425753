import { Button, Form, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import ProjectEnvSelect from './ProjectEnvSelect';
import TemplateSelect from './TemplateSelect';

const { Item } = Form;

const CopyTemplateModal = ({
  form,
  isModalOpen,
  onFinish,
  submitButton = 'Save',
  isLoading,
  handleCancel
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<CloseCircle />}
      wrapClassName="environment-modal copy-template-modal"
      destroyOnClose
    >
      <div className="add-container">
        <div className="d-flex flex-horizontal justify-between">
          <p className="add-title">Copy Templates To</p>
        </div>
        <div className="d-flex flex-vertical justify-between">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              name="projectEnv"
              label="Project Environment"
              rules={[
                {
                  required: true,
                  message: 'Please Select Project Environment!'
                }
              ]}
            >
              <ProjectEnvSelect />
            </Item>
            <Item
              name="template"
              label="Template"
              rules={[{ required: true, message: 'Please Select Templates!' }]}
            >
              <TemplateSelect />
            </Item>
          </Form>
        </div>
        <Space size="middle">
          <Button
            type="primary"
            className="primary-button"
            loading={isLoading}
            onClick={form?.submit}
          >
            {submitButton}
          </Button>
          <Button
            className="secondary-button"
            onClick={() => {
              handleCancel();
            }}
          >
            Cancel
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default CopyTemplateModal;
