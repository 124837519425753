/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  USERS: '/users',
  LOGOUT: '/logout',
  LOGIN: '/login',
  RESET: '/forgot-password',
  CHANGE: '/reset',
  VERIFY: '/verify-otp',
  USERS_MANAGEMENT: '/users',
  LANDING: '/landing',
  DASHBOARD: '/dashboard',
  ADDPROJECT: '/add-project',
  EDIT_PROJECT: '/edit-project',
  TEAMS: '/teams',
  MESSAGES: '/messages',
  EMAIL: '/email',
  PUSH: '/push',
  INAPP: '/in-app',
  SMS: '/sms',
  TEMPLATES: '/templates',
  ADDNEWPUSH: '/add-push',
  ANALYTICS: '/analytics',
  CHANNELS: '/channels',
  SENDERS: '/senders',
  LOGS: '/logs',
  PROJECTS: '/projects',
  DELIVERY: '/delivery',
  SETTING: '/setting',
  MEMBERS: '/members',
  AUTH_DETAILS: '/auth-details',
  AUTHENTICATION: '/authentication'
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  USERS_MANAGEMENT: 'User Management',
  TEAMS: 'Teams',
  PROJECTS: 'Projects',
  EMAIL: 'E-mail',
  SMS: 'SMS',
  ANALYTICS: 'Analytics',
  TEMPLATES: 'Templates',
  SENDERS: 'Senders',
  CHANNELS: 'Channels',
  LOGS: 'Logs',
  SETTING: 'Setting',
  MEMBERS: 'Members',
  AUTH_DETAILS: 'Auth Details'
};

export const DIVIDER = 'DIVIDER';

/* Authentication */
export const TOKEN = 'TOKEN';
export const USER = 'USER';
export const EMAIL = 'EMAIL';
export const FORM_DATA = 'formData';
export const PROJECT_ENV = 'PROJECT_ENV';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const PROJECT_KEYS = 'PROJECT_KEYS';
export const ACTIVE_TABS = 'ACTIVE_TABS';

export const OTP_SIZE = 6;

export const USERS_ROLE = {
  OWNER: 'OWNER',
  READ_ONLY: 'READ_ONLY',
  WRITE: 'WRITE'
};

export const ROLE = {
  READ: 'Read',
  READ_AND_WRITE: 'Read_and_Write'
};

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN'
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^([A-Za-z0-9_\-.+])+@(?!(?:[A-Za-z0-9_\-.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  MASK_4DIGIT: /.(?=.{4,}$)/g,
  NOT_ALLOWED_SPECIAL_CHAR_NUM: /^[a-zA-Z. ]*$/,
  NOT_ALLOWED_SPECIAL_CHAR: /^[a-zA-Z0-9. ]*$/,
  ALLOWED_HYPHEN_AND_CHAR: /^[a-zA-Z0-9- ]+$/
};

export const OTP_NUM_INPUT = 6;

export const EMAIL_STEPS = [
  {
    title: 'Configuration',
    content: 'First-content'
  },
  {
    title: 'Additional Settings',
    content: 'Second-content'
  },
  {
    title: 'Add Subscribers',
    content: 'Last-content'
  }
];

export const EMAIL_CHANNEL_STEPS = [
  {
    title: 'Email Detail',
    content: 'First-content'
  },
  {
    title: 'Delivery',
    content: 'Second-content'
  },
  {
    title: 'Audience',
    content: 'Last-content'
  }
];

export const NEW_MESSAGE_STEPS = [
  {
    title: 'Message Detail',
    content: 'First-content'
  },
  {
    title: 'Delivery',
    content: 'Second-content'
  },
  {
    title: 'Audience',
    content: 'Last-content'
  }
];

export const DOMAINS = {
  GMAIL: 'gmail.com',
  LOGICWIND: 'logicwind.com'
};

export const SMALL_TABLET = 768;
export const TABLET_BREAKPOINT = 1200;
export const BIG_SCREEN = 1600;

/* Table Height */
export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 263;

/* Form select dropdown default keys */
export const FORM_KEYS = {
  TEMPLATE: 'template',
  EMAIL: 'email'
};

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC'
};

export const EMAIL_SCHEDULED = {
  YES: 'YES',
  NO: 'NO'
};

export const DATE_FORMAT = 'DD/MM/YYYY, hh:mm:ss 	A';

export const SCHEDULED_DATE_FORMATE = 'MMM DD, YYYY hh:mm A';

export const PERMISSION = {
  READ_ONLY: { value: 'READ_ONLY', label: 'Read-Only' },
  WRITE: { value: 'WRITE', label: 'Write' },
  OWNER: { value: 'OWNER', label: 'Owner' }
};

export const LIMIT = 10;

export const PROVIDER_FIELD_TYPE = {
  STRING: 'string',
  NUMBER: 'number',
  PASSWORD: 'password'
};

export const LOG_MAIL_STATUS = {
  SUCCESS: { value: 'SUCCESS', label: 'Success' },
  PENDING: { value: 'PENDING', label: 'Pending' },
  SCHEDULED: { value: 'SCHEDULED', label: 'Scheduled' },
  FAILED: { value: 'FAILED', label: 'Failed' }
};

export const CUSTOM_DATE_FORMAT = 'DD MMM YY';

export const DAY_MONTH_FORMAT = 'DD MMM';

export const ANALYTICS_DATE_FORMAT = 'YYYY-MM-DD';

export const ANALYTICS_DATE_TIME_FORMAT = 'YYYY-MM-DD h:mm:ss';

export const MONTH_NAME_FORMAT = 'MMMM';

export const EMAIL_STATUSES = ['SUCCESS', 'FAILED', 'READ', 'UNREAD'];

export const CUSTOM_ANALYTICS_OPTIONS = [
  { label: 'YEARLY', value: 'YEARLY' },
  { label: 'THIS YEAR', value: 'THIS_YEAR' },
  { label: 'THIS MONTH', value: 'THIS_MONTH' },
  { label: 'CUSTOM', value: 'CUSTOM' }
];

export const STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  READ: 'READ',
  UNREAD: 'UNREAD'
};
