import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DATE_FORMAT, ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formatDate } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import './channels.less';
import AddChannel from './components/AddChannel';
import SendEmailModal from './components/SendEmailModal';
import ViewMembersModal from './components/ViewMembersModal';
import {
  CREATE_CHANNEL,
  DELETE_CHANNEL,
  UPDATE_CHANNEL
} from './graphql/Mutation';
import { GET_CHANNELS } from './graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10
};

const Channel = ({ setIsMailSend, activeKey }) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext);
  const userRole = getCurrentRole();
  const [sortedInfo, setSortedInfo] = useState({});
  const [isEmptyChannelList, setIsEmptyChannelList] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [channelList, setChannelList] = useState([]);
  const [isAddChannelModalOpen, setAddChannelModalOpen] = useState(false);
  const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false);
  const [isChannelLoading, setIsChannelLoading] = useState(true);
  const [isChannelSearchLoading, setIsChannelSearchLoading] = useState(false);
  const [isCreateChannelBtnLoading, setIsCreateChannelBtnLoading] = useState(
    false
  );
  const [channelDetails, setChannelDetails] = useState();
  const [channelId, setChannelId] = useState();
  const [channelSearchTerm, setChannelSearchTerm] = useState('');
  const [isViewMembersModalOpen, setIsViewMembersModalOpen] = useState(false);
  const [isAllowClear, setIsAllowClear] = useState(false);

  const limit = 10;

  const [executeCreateChannel] = useMutation(CREATE_CHANNEL, {
    onError: () => {}
  });
  const [executeUpdateChannel] = useMutation(UPDATE_CHANNEL, {
    onError: () => {}
  });
  const [executeDeleteChannel] = useMutation(DELETE_CHANNEL, {
    onError: () => {}
  });

  const [executeChannelList] = useLazyQuery(GET_CHANNELS, {
    onCompleted: (response) => {
      setChannelList([...channelList, ...response?.channels?.channels]);
      if (
        response?.channels?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyChannelList(true);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.channels?.count
      };
      setPaginationProp(pagination);
      setIsChannelLoading(false);
      setIsChannelSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsChannelLoading(false);
    }
  });

  useEffect(() => {
    setIsChannelLoading(true);
    setChannelList([]);
    if (state?.projectEnvId && activeKey === ROUTES?.CHANNELS) {
      executeChannelList({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            search: '',
            sortBy: 'createdAtDESC',
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, activeKey]);

  const handleAddChannelReset = () => {
    form?.resetFields();
    setChannelDetails();
  };

  const createChannelFun = async (channelDetail) => {
    setIsCreateChannelBtnLoading(true);
    const response = await executeCreateChannel({
      variables: {
        data: {
          name: channelDetail?.name?.trim(),
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL'
        }
      }
    });
    if (response?.data) {
      setAddChannelModalOpen(false);
      setIsChannelLoading(true);
      setChannelList([]);
      setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
      setChannelSearchTerm('');
      executeChannelList({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            search: '',
            sortBy: 'createdAtDESC',
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
      setTimeout(handleAddChannelReset, 500);
    }
    setIsCreateChannelBtnLoading(false);
  };

  const updateChannelFun = async (channelDetail) => {
    setIsCreateChannelBtnLoading(true);
    const response = await executeUpdateChannel({
      variables: {
        id: channelDetails?.id,
        data: {
          name: channelDetail?.name?.trim()
        }
      }
    });
    if (response?.data) {
      setAddChannelModalOpen(false);
      setIsChannelLoading(true);
      setChannelList([]);
      executeChannelList({
        variables: {
          filter: {
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: channelSearchTerm,
            sortBy: 'createdAtDESC',
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
      setTimeout(handleAddChannelReset, 500);
    }
    setIsCreateChannelBtnLoading(false);
  };

  const deleteChannelFun = async (id) => {
    const response = await executeDeleteChannel({
      variables: {
        id: id
      }
    });
    if (response?.data) {
      setIsChannelLoading(true);
      setChannelList([]);
      const lastPageEle =
        paginationProp?.total % paginationProp?.pageSize === 1 &&
        paginationProp?.total > 10;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current: paginationProp?.current - 1
        });
      }
      executeChannelList({
        variables: {
          filter: {
            skip: lastPageEle
              ? (paginationProp?.current - 1) *
                  (paginationProp?.pageSize || 0) -
                paginationProp?.pageSize
              : (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: channelSearchTerm,
            sortBy: 'createdAtDESC',
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
    }
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsChannelLoading(true);
    setChannelList([]);
    if (sorter?.column) {
      executeChannelList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: channelSearchTerm,
            sortBy:
              sorter?.order === 'ascend'
                ? `${sorter?.field}${SORT?.ASC}`
                : `${sorter?.field}${SORT?.DESC}`,
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
    } else {
      executeChannelList({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize,
            search: channelSearchTerm,
            sortBy: 'createdAtDESC',
            projectEnvId: state?.projectEnvId,
            type: 'EMAIL'
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    setIsChannelSearchLoading(true);
    const trimValue = value?.trim();
    setChannelSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsChannelLoading(true);
    setChannelList([]);
    executeChannelList({
      variables: {
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          sortBy: 'createdAtDESC',
          projectEnvId: state?.projectEnvId,
          type: 'EMAIL'
        }
      }
    });
    setIsAllowClear(false);
  };

  const AddChannelFormsItems = [
    {
      title: 'Name',
      name: 'name',
      placeholder: 'Enter channel name',
      rules: [{ required: true, message: 'Please enter channel name!' }]
    }
  ];

  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record, index) => {
        return (
          <span>
            {paginationProp?.pageSize * paginationProp?.current -
              paginationProp?.pageSize +
              index +
              1}
          </span>
        );
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'CREATED ON',
      dataIndex: 'created_on',
      key: 'created_on',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        return formatDate(record?.createdAt, DATE_FORMAT);
      }
    },
    {
      title: 'SEND MAIL',
      dataIndex: 'send_mail',
      key: 'send_mail',
      render: (_, record) => {
        return (
          <Button
            className="secondary-button mail"
            onClick={() => {
              setIsSendMailModalOpen(true);
              setChannelId(record?.id);
            }}
          >
            Mail
          </Button>
        );
      }
    },
    {
      title: 'MEMBERS',
      dataIndex: 'view_members',
      key: 'view_members',
      render: (_, record) => {
        return (
          <Button
            type="primary"
            className="primary-button mail"
            onClick={() => {
              setIsViewMembersModalOpen(true);
              setChannelId(record?.id);
            }}
          >
            View Members
          </Button>
        );
      }
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <>
            <div className="action-button">
              <Button type="link">
                <EditOutlined
                  onClick={() => {
                    setChannelDetails(record);
                    setAddChannelModalOpen(true);
                  }}
                />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.name}?`}
                onConfirm={() => deleteChannelFun(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      }
    }
  ]?.filter((item) => {
    if (
      userRole === USERS_ROLE?.READ_ONLY &&
      (item?.dataIndex === 'actions' || item?.dataIndex === 'send_mail')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyChannelList ? '' : <span />
  };

  return (
    <>
      <AddChannel
        initialValues={channelDetails}
        isModalOpen={isAddChannelModalOpen}
        setIsModalOpen={setAddChannelModalOpen}
        formTitle={channelDetails ? 'Edit Channel' : 'Add Channel'}
        onFinish={channelDetails ? updateChannelFun : createChannelFun}
        formItems={AddChannelFormsItems}
        submitButton={channelDetails ? 'Update' : 'Add'}
        loadings={isCreateChannelBtnLoading}
        form={form}
        handleReset={handleAddChannelReset}
      />
      <SendEmailModal
        isModalOpen={isSendMailModalOpen}
        setIsModalOpen={setIsSendMailModalOpen}
        formTitle="Send Email"
        submitButton="Send Email"
        channelId={channelId}
        setIsMailSend={setIsMailSend}
      />
      <ViewMembersModal
        isModalOpen={isViewMembersModalOpen}
        setIsModalOpen={setIsViewMembersModalOpen}
        channelId={channelId}
      />
      <div className="channels">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            {channelList?.length > 0 ||
            channelSearchTerm ||
            isChannelSearchLoading ||
            isAllowClear ? (
              <SearchWithLoading
                setIsAllowClear={setIsAllowClear}
                query={channelSearchTerm}
                setQuery={setChannelSearchTerm}
                className="search-input"
                getData={handleSearch}
              />
            ) : null}
          </div>
          {channelList?.length > 0 ||
          channelSearchTerm ||
          isChannelSearchLoading ||
          isAllowClear ? (
            <>
              {(userRole === USERS_ROLE?.OWNER ||
                userRole === USERS_ROLE?.WRITE) && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() => setAddChannelModalOpen(true)}
                >
                  Create New Channel
                </Button>
              )}
            </>
          ) : null}
        </div>
        <div className="mt-30 flex-vertical">
          {isChannelLoading ? (
            <LoaderComponent
              size="large"
              setHeight="60"
              spinning={isChannelLoading}
            />
          ) : (
            <>
              {channelList?.length > 0 ||
              channelSearchTerm ||
              isChannelSearchLoading ||
              isAllowClear ? (
                <CommonTable
                  locale={locale}
                  columns={columns}
                  data={channelList}
                  loading={isChannelLoading}
                  onChange={handleTableChange}
                  paginationConfig={paginationProp}
                  rowKey={(record) => record?.id}
                />
              ) : (
                <div className="analytics-illustration">
                  <Nodata />
                  <br />
                  {(userRole === USERS_ROLE?.OWNER ||
                    userRole === USERS_ROLE?.WRITE) && (
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() => setAddChannelModalOpen(true)}
                    >
                      Create New Channel
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Channel;
