import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { NoProjectIcon } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import history from '../../historyData';
import { PROJECT_LISTING } from '../projects/graphql/Queries';
import './dashboard.less';

const { TabPane } = Tabs;
const Dashboard = ({ defaultActiveKey }) => {
  const [projectLoading, setProjectLoading] = useState(true);
  const projectLimit = 10;

  useQuery(PROJECT_LISTING, {
    variables: {
      filter: {
        skip: 0,
        limit: projectLimit,
        search: '',
        sortBy: 'createdAtDESC'
      }
    },
    onCompleted: (response) => {
      setProjectLoading(false);
      if (response?.projects?.projects?.length > 0) {
        history?.push(
          `${ROUTES?.PROJECTS}/${response?.projects?.projects?.[0]?.id}${ROUTES?.EMAIL}${ROUTES?.ANALYTICS}`
        );
      }
    },
    fetchPolicy: 'network-only'
  });

  return (
    <>
      {projectLoading ? (
        <LoaderComponent size="large" spinning={projectLoading} />
      ) : (
        <div className="ant-layout">
          <Card className="dashboard full-height-card landing-card">
            <Tabs
              className="tabs no-border-top-left-radius without-sub-header-content"
              type="card"
              onChange={(e) => {
                history?.push(`/${e}`);
              }}
              defaultActiveKey={defaultActiveKey || '/'}
            >
              <TabPane tab="Projects" key="projects">
                <div className="fill-width">
                  <div className="d-flex justify-end">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="primary-button"
                      onClick={() =>
                        history?.push(
                          `${ROUTES?.PROJECTS}${ROUTES?.ADDPROJECT}`
                        )
                      }
                    >
                      Create a project
                    </Button>
                  </div>
                </div>
                <div className="no-project">
                  <div className="img-section">
                    <NoProjectIcon />
                  </div>
                  <div className="text-section d-flex align-center">
                    <span>
                      You have no new projects! Create a project and get
                      started!
                    </span>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </Card>
        </div>
      )}
    </>
  );
};

export default Dashboard;
