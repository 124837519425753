import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MoreIcon } from '../../../assets/svg';
import { ACTIVE_TABS, MODULES, ROUTES } from '../../../common/constants';
import Analytics from '../../analytics/Analytics';
import AuthDetail from '../../authDetails/AuthDetails';
import Channels from '../../channels/Channels';
import Logs from '../../logs/Logs';
import Senders from '../../senders/Senders';
import Setting from '../../setting/Setting';
import Templates from '../../template/Template';
import '../email.less';

const { TabPane } = Tabs;

const EmailTabs = ({ setActiveKey, activeKey }) => {
  const [isFirstTab, setIsFirstTab] = useState(false);
  const { projectId, id } = useParams();
  const history = useHistory();
  const [isMailSend, setIsMailSend] = useState(false);

  const handleKeyComponent = (keyPath) => {
    history?.push(`${ROUTES?.PROJECTS}/${projectId}${ROUTES?.EMAIL}${keyPath}`);
    if (keyPath === ROUTES?.ANALYTICS) {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
  };

  useEffect(() => {
    setActiveKey(`/${id}`);
    // eslint-disable-next-line no-undef
    localStorage.setItem(ACTIVE_TABS, `/${id}`);
    if (`/${id}` === ROUTES?.ANALYTICS) {
      setIsFirstTab(true);
    } else {
      setIsFirstTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Card className="full-height-card">
      <Tabs
        className={`${isFirstTab ? 'tabs no-border-top-left-radius' : 'tabs'}`}
        type="card"
        activeKey={activeKey}
        onChange={(e) => {
          handleKeyComponent(e);
        }}
        renderTabBar={(tabBarProps, DefaultTabBar) => {
          return (
            <DefaultTabBar
              {...tabBarProps}
              moreIcon={
                <>
                  <MoreIcon className="pointer" />
                </>
              }
            />
          );
        }}
      >
        <TabPane tab={MODULES?.ANALYTICS} key={ROUTES?.ANALYTICS}>
          <div className="tab-content-block">
            <Analytics activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.TEMPLATES} key={ROUTES?.TEMPLATES}>
          <div className="tab-content-block">
            <Templates activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.SENDERS} key={ROUTES?.SENDERS}>
          <div className="tab-content-block">
            <Senders activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.CHANNELS} key={ROUTES?.CHANNELS}>
          <div className="tab-content-block">
            <Channels setIsMailSend={setIsMailSend} activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.LOGS} key={ROUTES?.LOGS}>
          <div className="tab-content-block">
            <Logs
              isMailSend={isMailSend}
              setIsMailSend={setIsMailSend}
              activeKey={activeKey}
            />
          </div>
        </TabPane>
        <TabPane
          className="setting"
          tab={MODULES?.SETTING}
          key={ROUTES?.SETTING}
        >
          <div className="tab-content-block">
            <Setting activeKey={activeKey} />
          </div>
        </TabPane>
        <TabPane tab={MODULES?.AUTH_DETAILS} key={ROUTES?.AUTH_DETAILS}>
          <div className="tab-content-block">
            <AuthDetail activeKey={activeKey} />
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default EmailTabs;
