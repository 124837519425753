import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_CHANNELS = gql`
  query channels($filter: ChannelFilter!) {
    channels(filter: $filter) {
      count
      channels {
        id
        name
        projectEnvId
        createdBy
        createdAt
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const GET_CHANNEL_MEMBERS = gql`
  query channelEmailUsers($filter: ChannelMemberFilter!, $channelId: ID!) {
    channelEmailUsers(filter: $filter, channelId: $channelId) {
      count
      data {
        id
        email
        createdAt
      }
    }
  }
`;
