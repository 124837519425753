import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Email from './Email';

const EmailWrapper = ({ projectId, setActiveKey, activeKey }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.EMAIL}`}
        render={() => (
          <Redirect
            to={`${ROUTES?.PROJECTS}/${projectId}${ROUTES?.EMAIL}${ROUTES?.ANALYTICS}`}
          />
        )}
      />
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.EMAIL}/:id`}
        render={() => (
          <Email setActiveKey={setActiveKey} activeKey={activeKey} />
        )}
      />
    </Switch>
  );
};

export default EmailWrapper;
