import { useMutation } from '@apollo/client';
import { Button, Spin } from 'antd';
import { get } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { OTP_NUM_INPUT, OTP_SIZE, ROUTES } from '../../common/constants';
import { SEND_OTP, VERIFY_OTP } from './graphql/Mutations';

const OTP = () => {
  const history = useHistory();
  const loginButtonRef = useRef(null);
  const { initializeAuth, dispatch, getCurrentUser } = useContext(AppContext);
  const [otpState, setOtpState] = useState();
  const currentUser = getCurrentUser();
  const [verifyOtpFun, { loading: verifyOtpLoading }] = useMutation(
    VERIFY_OTP,
    {
      onError: () => {}
    }
  );
  const [sendOtpFun] = useMutation(SEND_OTP, {
    variables: {
      email: currentUser?.email
    }
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    setOtpState();
  }

  useEffect(() => {
    if (currentUser?.email) {
      history?.replace(ROUTES?.VERIFY);
    } else {
      history?.replace(ROUTES?.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.email]);

  useEffect(() => {
    if (otpState?.length === OTP_SIZE) {
      loginButtonRef?.current?.focus();
    }
  }, [otpState]);

  const handleOTPChange = (value) => {
    setOtpState(value);
  };

  const handleSendAgain = async () => {
    try {
      const response = await sendOtpFun();
      const data = response?.data;
      if (data) {
        dispatch({
          type: 'SET_CURRENT_USER',
          data: { email: currentUser?.email }
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleLogin = async () => {
    try {
      const response = await verifyOtpFun({
        variables: {
          data: {
            email: currentUser?.email,
            otp: otpState
          }
        }
      });
      const accessToken = get(response, 'data.verifyOtp.token');
      const userData = get(response, 'data.verifyOtp.data');
      const refreshToken = get(response, 'data.verifyOtp.refreshToken');
      if (successCallback) {
        successCallback(accessToken, userData, refreshToken);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="gx-login-container login">
      <Spin spinning={false} wrapperClassName="gx-login-content">
        <div className="gx-text-center site-title mb-32">Sendbay</div>
        <div className="gx-login-header">
          <span className="gx-login-header-title white-color mb-8">
            Check your email
          </span>
          <span className="gx-login-header-subtitle white-color">
            Enter OTP sent to {currentUser?.email}
          </span>
        </div>
        <div className="mb-16">
          <OtpInput
            value={otpState}
            onChange={handleOTPChange}
            numInputs={OTP_NUM_INPUT}
            className="sb-otp-input"
          />
        </div>
        <Button
          loading={verifyOtpLoading}
          type="primary"
          className="primary-button mb-16"
          onClick={handleLogin}
          ref={loginButtonRef}
        >
          Login
        </Button>
        <div>
          <span className="white-color">Didn't receive OTP ? </span>
          <span onClick={handleSendAgain}>
            <Button
              type="link"
              disabled={verifyOtpLoading}
              className="sb-send-again"
            >
              Send Again
            </Button>
          </span>
        </div>
      </Spin>
    </div>
  );
};

export default OTP;
