import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Mutations';
import { GET_PROFILE } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const history = useHistory();
  const refreshToken = getRefreshToken();

  const [getProfile, { loading: profileLoading }] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onError() {
      history?.replace(ROUTES?.LOGOUT);
    }
  });

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    async onCompleted(response) {
      const accessToken = response?.refreshToken?.token;
      const res = await getProfile({
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      });
      const userData = res?.user;
      initializeAuth(accessToken, userData, userData?.refreshToken);
      history?.push(ROUTES?.DASHBOARD);
    },
    onError() {
      history?.replace(ROUTES?.LOGOUT);
    }
  });

  useEffect(() => {
    refresh({
      variables: {
        token: refreshToken
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || profileLoading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
