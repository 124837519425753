import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Modal, Popconfirm } from 'antd';

import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CloseCircle } from '../../../assets/svg';
import { USERS_ROLE } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import LoaderComponent from '../../../components/LoaderComponent';
import Nodata from '../../../components/Nodata';
import SearchComponent from '../../../components/SearchComponent';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { ADD_MEMBERS, DELETE_MEMBER } from '../graphql/Mutation';
import { GET_CHANNEL_MEMBERS } from '../graphql/Queries';
import AddMembersModal from './AddMembersModal';

const ViewMembersModal = ({ isModalOpen, setIsModalOpen, channelId }) => {
  const [form] = Form.useForm();
  const { state, getCurrentRole } = useContext(AppContext);
  const userRole = getCurrentRole();
  const [channelMembers, setChannelMembers] = useState([]);
  const [isChannelMemberLoading, setIsChannelMemberLoading] = useState(true);
  const [channelMembersSearchTerm, setChannelMembersSearchTerm] = useState('');
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false);
  const [isAddMembersBtnLoading, setIsAddMembersBtnLoading] = useState(false);
  const [isEmptyChannelMembersList, setIsEmptyChannelMembersList] = useState(
    false
  );
  const [isFetchMoreMembersLoading, setIsFetchMoreMembersLoading] = useState(
    false
  );
  const [isActive, setIsActive] = useState(true);

  const limit = 10;

  const [executeAddMembers] = useMutation(ADD_MEMBERS, {
    onError: () => {}
  });
  const [executeDeleteMember] = useMutation(DELETE_MEMBER, {
    onError: () => {}
  });

  const [executeChannelMembersList] = useLazyQuery(GET_CHANNEL_MEMBERS, {
    onCompleted: (response) => {
      if (response?.channelEmailUsers?.data?.length < limit) {
        setIsActive(false);
      }
      if (response?.channelEmailUsers?.count === 0) {
        setIsEmptyChannelMembersList(true);
      }
      setChannelMembers([
        ...channelMembers,
        ...response?.channelEmailUsers?.data
      ]);
      setIsChannelMemberLoading(false);
      setIsFetchMoreMembersLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  useEffect(() => {
    if (state?.projectEnvId && channelId && isModalOpen) {
      setIsChannelMemberLoading(true);
      setIsActive(true);
      setChannelMembers([]);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: 0,
            limit,
            sortBy: 'createdAtDESC'
          },
          channelId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId, state?.projectEnvId, isModalOpen]);

  const handleReset = () => {
    form?.resetFields();
  };

  const addMembersFun = async (membersDetail) => {
    setIsAddMembersBtnLoading(true);
    const response = await executeAddMembers({
      variables: {
        data: {
          emails: membersDetail?.email
        },
        channelId
      }
    });
    if (response?.data) {
      setIsAddMembersModalOpen(false);
      setIsChannelMemberLoading(true);
      setChannelMembers([]);
      setChannelMembersSearchTerm('');
      setIsActive(true);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: 0,
            limit,
            sortBy: 'createdAtDESC'
          },
          channelId
        }
      });
      setTimeout(handleReset, 500);
    }
    setIsAddMembersBtnLoading(false);
  };

  const deleteMemberFun = async (id) => {
    const response = await executeDeleteMember({
      variables: {
        channelMemberId: id,
        channelId
      }
    });
    if (response?.data) {
      setIsChannelMemberLoading(true);
      setChannelMembers([]);
      setIsActive(true);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: 0,
            limit,
            search: channelMembersSearchTerm,
            sortBy: 'createdAtDESC'
          },
          channelId
        }
      });
    }
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setChannelMembersSearchTerm(trimValue);
    setIsChannelMemberLoading(true);
    setChannelMembers([]);
    setIsActive(true);
    executeChannelMembersList({
      variables: {
        filter: {
          skip: 0,
          limit,
          search: trimValue,
          sortBy: 'createdAtDESC'
        },
        channelId
      }
    });
  };

  const handleMembersScroll = () => {
    if (!isFetchMoreMembersLoading && !isChannelMemberLoading) {
      const currentLength = channelMembers?.length;
      setIsFetchMoreMembersLoading(true);
      executeChannelMembersList({
        variables: {
          filter: {
            skip: currentLength,
            limit,
            search: '',
            sortBy: 'createdAtDESC'
          },
          channelId
        }
      });
    }
  };

  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record, index) => {
        return <span>{index + 1}</span>;
      }
    },
    {
      title: 'EMAIl',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      width: 300,
      align: 'left',
      className: 'max-width-column'
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      width: 250,
      render: (_, record) => {
        return (
          <>
            <div className="action-button">
              <Popconfirm
                title={`Are you sure you want to delete ${record?.email}?`}
                onConfirm={() => deleteMemberFun(record?.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      }
    }
  ]?.filter((item) => {
    if (userRole === USERS_ROLE?.READ_ONLY && item?.dataIndex === 'actions') {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyChannelMembersList ? '' : <span />
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks, no-use-before-define
  const infiniteScrollRef = useInfiniteScroll(handleMembersScroll, isActive);

  return (
    <Modal
      centered
      closeIcon={<CloseCircle />}
      wrapClassName="member-modal"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      forceRender
      width={1000}
    >
      <AddMembersModal
        form={form}
        isModalOpen={isAddMembersModalOpen}
        setIsModalOpen={setIsAddMembersModalOpen}
        formTitle="Add Members"
        submitButton="Add"
        handleReset={handleReset}
        onFinish={addMembersFun}
        loadings={isAddMembersBtnLoading}
      />
      <div className="channels">
        <p className="add-title">Members</p>
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            <SearchComponent
              query={channelMembersSearchTerm}
              setQuery={setChannelMembersSearchTerm}
              className="search-input"
              getData={handleSearch}
            />
          </div>
          <>
            {(userRole === USERS_ROLE?.OWNER ||
              userRole === USERS_ROLE?.WRITE) && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="primary-button"
                onClick={() => setIsAddMembersModalOpen(true)}
              >
                Add Members
              </Button>
            )}
          </>
        </div>
        <div className="mt-30 flex-vertical">
          {isChannelMemberLoading ? (
            <div className="view-member-modal">
              <LoaderComponent
                size="large"
                setHeight="60"
                spinning={isChannelMemberLoading}
              />
            </div>
          ) : (
            <>
              {channelMembers?.length > 0 ? (
                <CommonTable
                  className="members-table"
                  locale={locale}
                  columns={columns}
                  data={channelMembers}
                  loading={isChannelMemberLoading || isFetchMoreMembersLoading}
                  scroll={{ y: 350 }}
                  rowKey={(record) => record?.id}
                  onRow={() => {
                    return {
                      ref: infiniteScrollRef
                    };
                  }}
                />
              ) : (
                <div className="analytics-illustration-members">
                  <Nodata />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ViewMembersModal;
