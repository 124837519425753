import { Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../AppContext';
import './App.css';
import Sidebar from './components/sidebar/Sidebar';
import ContentRoutes from './ContentRoutes';

const { Content } = Layout;

const App = () => {
  const { dispatch, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch({ type: 'SET_CURRENT_ROLE', data: 'OWNER' });
  }, [dispatch, currentUser?.email]);

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Content className="gx-layout-content">
          <div className="gx-main-content-wrapper">
            <div className="env-dropdown" id="env-dropdown" />

            <ContentRoutes />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
