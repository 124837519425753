import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROFILE = gql`
  query User {
    user {
      createdAt
      email
      firstName
      id
      lastName
      profileImage
      message
      refreshToken
    }
  }
`;
