import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query users($filter: UserFilter!) {
    users(filter: $filter) {
      users {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
