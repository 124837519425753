import { gql } from '@apollo/client';

export const GET_PLATFORMS = gql`
  query platforms($projectEnvId: ID!, $filter: PlatformFilter!) {
    platforms(projectEnvId: $projectEnvId, filter: $filter) {
      platforms {
        id
        name
        projectEnvId
        createdAt
        platformConfigs
        provider {
          id
          slug
          name
          providerConfigs
        }
      }
    }
  }
`;

export const GET_PROVIDERS = gql`
  query providers($filter: ProviderFilter!) {
    providers(filter: $filter) {
      providers {
        id
        name
        type
        providerConfigs
        slug
      }
    }
  }
`;

export const GET_PLATFORM = gql`
  query platform($id: ID!) {
    platform(id: $id) {
      id
      name
      projectEnvId
      platformConfigs
      createdAt
      updatedAt
    }
  }
`;

export const GET_PROVIDER = gql`
  query provider($id: ID!) {
    provider(id: $id) {
      id
      name
      providerConfigs
      type
      slug
    }
  }
`;

export const GET_PLATFORM_CONFIGS = gql`
  query getPlatformConfigs($id: ID!, $type: PlatformType!) {
    getPlatformConfigs(id: $id, type: $type) {
      platformConfigs
      status
    }
  }
`;
