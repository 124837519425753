import { Button, Form, Modal, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import { REGEX } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';

const AddMembersModal = ({
  form,
  formTitle,
  submitButton,
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  handleReset
}) => {
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  useEffect(() => {
    form?.setFieldsValue();
  }, [form]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setTimeout(handleReset, 500);
  };

  useEffect(() => {
    if (isModalOpen) return;
    form?.resetFields();
  }, [form, isModalOpen]);

  const emailValidator = (rule, values) => {
    const invalidInputs = values?.filter(
      (value) => !value?.match(REGEX?.EMAIL)
    );
    setInvalidEmails(invalidInputs);
    if (!values || values?.length === 0) {
      return Promise?.reject(new Error(`Please enter users!`));
    }
    if (invalidInputs?.length === 1) {
      return Promise?.reject(
        new Error(`${invalidInputs?.join('')} is not a valid email`)
      );
    }
    if (invalidInputs?.length > 1) {
      return Promise?.reject(
        new Error(
          `${invalidInputs?.slice(0, -1).join(', ')} and ${invalidInputs?.slice(
            -1
          )} are not valid emails`
        )
      );
    }
    return Promise?.resolve();
  };

  const onPreventMouseDown = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  return (
    <Modal
      centered
      closeIcon={<CloseCircle />}
      wrapClassName="environment-modal channel-modal"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      onOk={form?.submit}
      forceRender
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
        onFinishFailed={() => setIsValidateTrigger(true)}
      >
        <div className="channel add-container add-member">
          <div className="d-flex flex-horizontal justify-between">
            <p className="add-title">{formTitle}</p>
          </div>
          <div className="d-flex flex-vertical justify-between">
            <Form.Item
              label="Enter email"
              name="email"
              rules={[
                {
                  validator: emailValidator,
                  required: true
                }
              ]}
            >
              <SelectComponent
                mode="tags"
                placeholder="Enter email"
                dropdownClassName="select_dropdown"
                tokenSeparators={[',', ' ']}
                tagRender={({ label, value, closable, onClose }) => (
                  <Tag
                    closable={closable}
                    onClose={(e) => {
                      e?.preventDefault();
                      onClose();
                    }}
                    onMouseDown={onPreventMouseDown}
                    color={invalidEmails?.includes(value) ? 'red' : ''}
                  >
                    {label}
                  </Tag>
                )}
              />
            </Form.Item>
          </div>
          <Space size="middle" className="mt-20 ">
            <Button
              type="primary"
              htmlType="submit"
              className="primary-button"
              loading={loadings}
            >
              {submitButton}
            </Button>
            <Button
              className="secondary-button"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default AddMembersModal;
