import { Line } from '@ant-design/plots';
import React from 'react';

const LineChart = ({ data }) => {
  const config = {
    data,
    xField: 'label',
    yField: 'count',
    seriesField: 'status',
    legend: {
      position: 'top',
      itemName: {
        style: { marginTop: '30px' }
      }
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000
      }
    }
  };
  return <Line {...config} className="line-chart" />;
};

export default LineChart;
