import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form, Popconfirm } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES, SORT, USERS_ROLE } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import Nodata from '../../components/Nodata';
import SearchWithLoading from '../../components/SearchWithLoading';
import CopyTemplateModal from './components/CopyTemplateModal';
import EditBodyModal from './components/EditBodyModal';
import PreviewModal from './components/PreviewModal';
import TemplateModal from './components/TemplateModal';
import {
  COPY_TEMPLATES,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  UPDATE_TEMPLATE
} from './graphql/Mutation';
import { TEMPLATES } from './graphql/Queries';
import './template.less';

const { textSpecialChar } = formValidatorRules;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10
};

const Templates = ({ activeKey }) => {
  const [form] = Form.useForm();
  const [copyTemplateForm] = Form.useForm();
  const { id } = useParams();
  const { state, getCurrentRole } = useContext(AppContext);
  const userRole = getCurrentRole();
  const { pathname } = useLocation();
  const [sortedInfo, setSortedInfo] = useState({});
  const [isEmptyTemplateList, setIsEmptyTemplateList] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  let emailKey = '';
  const [isEditBody, setIsEditBody] = useState(false);
  const [templateSearchTerm, setTemplateSearchTerm] = useState('');
  const [isAddTemplateModalOpen, setAddTemplateModalOpen] = useState(false);
  const [isTemplateBody, setIsTemplateBody] = useState([]);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editRowData, setEditRowData] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [
    isCreateEditTemplateBtnLoading,
    setIsCreateEditTemplateBtnLoading
  ] = useState(false);
  const [isEditBodyModalOpen, setIsEditBodyModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isTemplateSearchLoading, setIsTemplateSearchLoading] = useState(false);
  const [isAllowClear, setIsAllowClear] = useState(false);
  const [isCopyTemplateModalOpen, setCopyTemplateModalOpen] = useState(false);
  const [isCopyTemplateLoading, setCopyTemplateLoading] = useState(false);

  const limit = 10;

  const [executeTemplate] = useLazyQuery(TEMPLATES, {
    onCompleted: (response) => {
      setTemplateList([...response?.templates?.templates]);
      if (
        response?.templates?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyTemplateList(true);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: limit,
        total: response?.templates?.count
      };
      setPaginationProp(pagination);
      setLoadings(false);
      setIsTemplateSearchLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setLoadings(false);
    }
  });

  const [addTemplate] = useMutation(CREATE_TEMPLATE, {
    onError: () => {}
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    onError: () => {}
  });
  const [executeDeleteTemplate] = useMutation(DELETE_TEMPLATE, {
    onError: () => {}
  });
  const [executeCopyTemplate] = useMutation(COPY_TEMPLATES, {
    onError: () => {}
  });

  useEffect(() => {
    setLoadings(true);
    setTemplateList([]);
    if (activeKey === ROUTES?.TEMPLATES) {
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: paginationProp?.pageSize || limit,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, activeKey]);

  useEffect(() => {
    if (isEditBody) {
      setLoadings(true);
      executeTemplate({
        variables: {
          filter: {
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditBody]);

  useEffect(() => {
    setIsEditBody(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleReset = () => {
    setIsEditModal(false);
    form?.resetFields();
    setEditRowData({});
  };

  const onFinish = (values) => {
    if (`/${pathname?.split('/')[3]}` === ROUTES?.EMAIL) {
      emailKey = 'EMAIL';
    }
    if (isEditModal) {
      setIsCreateEditTemplateBtnLoading(true);
      updateTemplate({
        variables: {
          data: {
            title: values?.title?.trim(),
            subject: values?.subject?.trim(),
            projectEnvId: state?.projectEnvId,
            type: emailKey
          },
          id: editRowData?.id
        }
      })
        .then(() => {
          setLoadings(true);
          setAddTemplateModalOpen(false);
          setIsCreateEditTemplateBtnLoading(false);
          setEditRowData({});
          setIsEditModal(false);
          executeTemplate({
            variables: {
              filter: {
                skip:
                  (paginationProp?.current - 1) *
                  (paginationProp?.pageSize || 0),
                limit: paginationProp?.pageSize || limit,
                search: templateSearchTerm,
                projectEnvId: state?.projectEnvId,
                sortBy: 'createdAtDESC',
                type: 'EMAIL'
              }
            }
          });
          setTimeout(handleReset, 500);
        })
        .catch((error) => {
          setLoadings(false);
          return error;
        });
    } else {
      setIsCreateEditTemplateBtnLoading(true);
      addTemplate({
        variables: {
          data: {
            key: values?.key?.trim(),
            title: values?.title?.trim(),
            subject: values?.subject?.trim(),
            projectEnvId: state?.projectEnvId,
            type: emailKey
          }
        }
      })
        .then(() => {
          setAddTemplateModalOpen(false);
          setIsCreateEditTemplateBtnLoading(false);
          setLoadings(true);
          setEditRowData({});
          setIsEditModal(false);
          setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
          setTemplateSearchTerm('');
          executeTemplate({
            variables: {
              filter: {
                skip: 0,
                limit: paginationProp?.pageSize || limit,
                projectEnvId: state?.projectEnvId,
                sortBy: 'createdAtDESC',
                type: 'EMAIL'
              }
            }
          });
          setTimeout(handleReset, 500);
        })
        .catch((error) => {
          setLoadings(false);
          return error;
        });
    }
  };

  const saveTemplateBody = (values) => {
    if (`/${pathname?.split('/')[3]}` === ROUTES?.EMAIL) {
      emailKey = 'EMAIL';
    }
    setLoadings(true);
    updateTemplate({
      variables: {
        data: {
          title: editRowData?.title?.trim(),
          subject: editRowData?.subject?.trim(),
          projectEnvId: state?.projectEnvId,
          type: emailKey,
          body: values?.body,
          design: values?.design
        },
        id: editRowData?.id
      }
    })
      .then(() => {
        setLoadings(false);
        setEditRowData({});
        setAddTemplateModalOpen(false);
        setIsEditModal(false);
        executeTemplate({
          variables: {
            filter: {
              skip:
                (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
              limit: paginationProp?.pageSize || limit,
              projectEnvId: state?.projectEnvId,
              sortBy: 'createdAtDESC',
              type: 'EMAIL'
            }
          }
        });
        handleReset();
      })
      .catch((error) => {
        setLoadings(false);
        return error;
      });
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    setLoadings(true);
    setTemplateList([]);
    if (sorter?.column) {
      executeTemplate({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: templateSearchTerm,
            projectEnvId: state?.projectEnvId,
            sortBy:
              sorter?.order === 'ascend'
                ? `${sorter?.field}${SORT?.ASC}`
                : `${sorter?.field}${SORT?.DESC}`,
            type: 'EMAIL'
          }
        }
      });
    } else {
      executeTemplate({
        variables: {
          filter: {
            skip,
            limit: pagination?.pageSize || limit,
            search: templateSearchTerm,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
  };

  const onDeleteData = async (record) => {
    const response = await executeDeleteTemplate({
      variables: {
        id: record?.id
      }
    });
    if (response?.data) {
      setLoadings(true);
      setEditRowData({});
      setAddTemplateModalOpen(false);
      setIsEditModal(false);
      const lastPageEle =
        paginationProp?.total % paginationProp?.pageSize === 1 &&
        paginationProp?.total > 10;
      if (lastPageEle) {
        setPaginationProp({
          ...paginationProp,
          current: paginationProp?.current - 1
        });
      }
      executeTemplate({
        variables: {
          filter: {
            skip: lastPageEle
              ? (paginationProp?.current - 1) *
                  (paginationProp?.pageSize || 0) -
                paginationProp?.pageSize
              : (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || limit,
            search: templateSearchTerm,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
  };

  const handleSearch = (value) => {
    setIsTemplateSearchLoading(true);
    const trimValue = value?.trim();
    setTemplateSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setLoadings(true);
    setTemplateList([]);
    executeTemplate({
      variables: {
        filter: {
          skip: 0,
          limit: paginationProp?.pageSize || limit,
          search: trimValue,
          projectEnvId: state?.projectEnvId,
          sortBy: 'createdAtDESC',
          type: 'EMAIL'
        }
      }
    });
    setIsAllowClear(false);
  };

  const AddTemplateFormsItems = [
    {
      title: 'Key',
      name: 'key',
      placeholder: 'Enter template key',
      rules: [{ required: true, message: 'Please input template key!' }]
    },
    {
      title: 'Title',
      name: 'title',
      placeholder: 'Enter template title',
      rules: [
        { required: true, message: 'Please input template title!' },
        textSpecialChar
      ]
    },
    {
      title: 'Subject',
      name: 'subject',
      placeholder: 'Enter template subject',
      rules: [{ required: true, message: 'Please input template subject!' }]
    }
  ];

  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record, index) => {
        return (
          <span>
            {paginationProp?.pageSize * paginationProp?.current -
              paginationProp?.pageSize +
              index +
              1}
          </span>
        );
      }
    },
    {
      title: 'KEY',
      dataIndex: 'key',
      key: 'key',
      sorter: true,
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'key' && sortedInfo?.order
    },
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column'
    },
    {
      title: 'SUBJECT',
      dataIndex: 'subject',
      key: 'subject',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column'
    },
    {
      title: 'BODY',
      dataIndex: 'body',
      key: 'body',
      render: (_, record) => {
        return (
          <>
            {!record?.body ? (
              <Button
                type="primary"
                className="secondary-button edit-body"
                onClick={() => {
                  setIsEditBodyModalOpen(true);
                  setEditRowData(record);
                  setIsTemplateBody(record?.body);
                  setIsEditBody(true);
                }}
              >
                Add Body
              </Button>
            ) : (
              <div className="d-flex gap-16 justify-center align-center">
                <Button
                  className="primary-button edit-body"
                  onClick={() => {
                    setIsEditBodyModalOpen(true);
                    setEditRowData(record);
                    setIsTemplateBody(record?.body);
                    setIsEditBody(true);
                  }}
                >
                  Edit Body
                </Button>
                {record?.body && (
                  <Button
                    className="primary-button edit-body"
                    onClick={() => {
                      setIsPreviewModalOpen(true);
                      setIsTemplateBody(record?.body);
                    }}
                  >
                    Preview
                  </Button>
                )}
              </div>
            )}
          </>
        );
      }
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <>
            <div className="action-button">
              <Button
                type="link"
                onClick={() => {
                  setEditRowData(record);
                  setAddTemplateModalOpen(true);
                  setIsEditModal(true);
                }}
              >
                <EditOutlined />
              </Button>
              <Popconfirm
                title={`Are you sure you want to delete ${record?.title}?`}
                onConfirm={() => onDeleteData(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      }
    }
  ]?.filter((item) => {
    if (
      userRole === USERS_ROLE?.READ_ONLY &&
      (item?.dataIndex === 'actions' || item?.dataIndex === 'body')
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyTemplateList ? '' : <span />
  };

  const handleCopyTemplate = async (values) => {
    setCopyTemplateLoading(true);
    const res = await executeCopyTemplate({
      variables: {
        data: {
          projectEnvId: values?.projectEnv,
          templateIds: values?.template
        }
      }
    });
    if (res?.data) {
      copyTemplateForm.resetFields();
      setCopyTemplateModalOpen(false);
    }
    setCopyTemplateLoading(false);
  };

  const handleCancel = () => {
    copyTemplateForm.resetFields();
    setCopyTemplateModalOpen(false);
  };

  return (
    <>
      <CopyTemplateModal
        form={copyTemplateForm}
        isModalOpen={isCopyTemplateModalOpen}
        onFinish={handleCopyTemplate}
        isLoading={isCopyTemplateLoading}
        handleCancel={handleCancel}
      />
      <TemplateModal
        initialValues={editRowData}
        isModalOpen={isAddTemplateModalOpen}
        setIsModalOpen={setAddTemplateModalOpen}
        formTitle={isEditModal ? 'Edit Template' : 'Add Template'}
        onFinish={onFinish}
        formItems={AddTemplateFormsItems}
        submitButton={isEditModal ? 'Update' : 'Add'}
        loadings={isCreateEditTemplateBtnLoading}
        form={form}
        handleReset={handleReset}
      />
      <div className="template">
        {isEditBody ? (
          <EditBodyModal
            isModalOpen={isEditBodyModalOpen}
            setIsModalOpen={setIsEditBodyModalOpen}
            setIsEditBody={setIsEditBody}
            isTemplateBody={isTemplateBody}
            saveTemplateBody={saveTemplateBody}
            handleReset={handleReset}
            editRowData={editRowData}
          />
        ) : (
          <>
            <div className="d-flex justify-between">
              <div className="width-percent-40">
                {templateList?.length > 0 ||
                templateSearchTerm ||
                isTemplateSearchLoading ||
                isAllowClear ? (
                  <SearchWithLoading
                    setIsAllowClear={setIsAllowClear}
                    query={templateSearchTerm}
                    setQuery={setTemplateSearchTerm}
                    className="search-input"
                    getData={handleSearch}
                  />
                ) : null}
              </div>
              <div>
                {templateList?.length > 0 ? (
                  <>
                    {(userRole === USERS_ROLE?.OWNER ||
                      userRole === USERS_ROLE?.WRITE) && (
                      <Button
                        type="primary"
                        className="primary-button"
                        onClick={() => setCopyTemplateModalOpen(true)}
                      >
                        Copy Templates
                      </Button>
                    )}
                  </>
                ) : null}
                {templateList?.length > 0 ||
                templateSearchTerm ||
                isTemplateSearchLoading ||
                isAllowClear ? (
                  <>
                    {(userRole === USERS_ROLE?.OWNER ||
                      userRole === USERS_ROLE?.WRITE) && (
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className="primary-button ml-10"
                        onClick={() => setAddTemplateModalOpen(true)}
                      >
                        Create New Template
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div className="mt-30 flex-vertical">
              {loadings ? (
                <LoaderComponent
                  size="large"
                  setHeight="60"
                  spinning={loadings}
                />
              ) : (
                <>
                  {templateList?.length > 0 ||
                  templateSearchTerm ||
                  isTemplateSearchLoading ||
                  isAllowClear ? (
                    <CommonTable
                      locale={locale}
                      loading={loadings}
                      columns={columns}
                      data={templateList}
                      onChange={handleTableChange}
                      paginationConfig={paginationProp}
                      rowKey={(record) => record?.id}
                    />
                  ) : (
                    <div className="analytics-illustration">
                      <Nodata />
                      <br />
                      {templateList.length > 0 ? null : (
                        <>
                          {(userRole === USERS_ROLE?.OWNER ||
                            userRole === USERS_ROLE?.WRITE) && (
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              className="primary-button"
                              onClick={() => setAddTemplateModalOpen(true)}
                            >
                              Create New Template
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            {isPreviewModalOpen && (
              <PreviewModal
                isModalOpen={isPreviewModalOpen}
                setIsModalOpen={setIsPreviewModalOpen}
                isTemplateBody={isTemplateBody}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Templates;
