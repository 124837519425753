import { Steps } from 'antd';
import { React, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { USERS_ROLE } from '../../../common/constants';
import CreateEditPlatformForm from './CreateEditPlatformForm';
import PlatformList from './PlatformList';

const { Step } = Steps;

const PlatformSteps = ({ activeKey }) => {
  const [current, setCurrent] = useState(0);
  const [providerId, setProviderId] = useState('');
  const [providerValue, setProviderValue] = useState('');
  const [providerSlug, setProviderSlug] = useState('');
  const [platformDetail, setPlatformDetail] = useState();
  const { getCurrentRole } = useContext(AppContext);
  const userRole = getCurrentRole();

  const steps = [
    {
      title: 'Select Platform Provider',
      content: (
        <PlatformList
          providerId={providerId}
          setProviderId={setProviderId}
          setProviderValue={setProviderValue}
          platformDetail={platformDetail}
          setPlatformDetail={setPlatformDetail}
          setProviderSlug={setProviderSlug}
          activeKey={activeKey}
        />
      )
    },
    {
      title: 'Add Configuration',
      content: (
        <CreateEditPlatformForm
          current={current}
          providerId={providerId}
          providerValue={providerValue}
          platformDetail={platformDetail}
          setCurrent={setCurrent}
          setProviderId={setProviderId}
          setPlatformDetail={setPlatformDetail}
          providerSlug={providerSlug}
        />
      )
    }
  ];

  useEffect(() => {
    if ((providerId || platformDetail) && current < steps?.length - 1) {
      if (userRole === USERS_ROLE?.OWNER || userRole === USERS_ROLE?.WRITE) {
        const nextCurrent = current + 1;
        setCurrent(nextCurrent);
      }
    }
  }, [current, providerId, steps.length, platformDetail, userRole]);

  const onChange = (value) => {
    setProviderId('');
    setCurrent(value);
  };

  return (
    <div>
      {current !== 0 && !platformDetail && (
        <div>
          <Steps current={current} onChange={onChange}>
            {steps?.map((item) => (
              <Step key={item?.title} title={item?.title} />
            ))}
          </Steps>
        </div>
      )}
      <div className="steps-content">{steps?.[current]?.content}</div>
    </div>
  );
};

export default PlatformSteps;
