import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PROJECT_ENV } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { PROJECT_ENVIRONMENT } from '../../projects/graphql/Queries';

const { Option } = Select;

const ProjectEnvSelect = (props) => {
  const { projectId } = useParams();
  const { ...rest } = props;
  const [projectEnvList, setProjectEnvList] = useState([]);
  const [envValue, setEnvValue] = useState();

  const [
    executeProjectEnvironment,
    { loading: isProjectEnvLoading }
  ] = useLazyQuery(PROJECT_ENVIRONMENT, {
    onCompleted: (response) => {
      setProjectEnvList([
        ...projectEnvList,
        ...response?.projectEnvironment?.environments
      ]);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  useEffect(() => {
    setProjectEnvList([]);
    executeProjectEnvironment({
      variables: {
        projectId
      }
    });
    // eslint-disable-next-line no-undef
    setEnvValue(localStorage.getItem(PROJECT_ENV));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterOption = (input, option) =>
    (option?.children ?? '')?.toLowerCase()?.includes(input?.toLowerCase());

  return (
    <SelectComponent
      showSearch
      loading={isProjectEnvLoading}
      placeholder="Select Project Environment"
      optionFilterProp="children"
      filterOption={filterOption}
      notFoundContent={
        isProjectEnvLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {projectEnvList
        ?.filter((projectEnv) => projectEnv?.id !== envValue)
        ?.map((projectEnv) => (
          <Option key={projectEnv?.id} value={projectEnv?.id}>
            {projectEnv?.environment}
          </Option>
        ))}
    </SelectComponent>
  );
};

export default ProjectEnvSelect;
