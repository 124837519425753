import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SelectComponent from '../../../components/SelectComponent';
import { TEMPLATES } from '../graphql/Queries';

const { Option } = Select;

const LIMIT = 10;

const TemplateSelect = (props) => {
  const { ...rest } = props;
  const { state } = useContext(AppContext);
  const [templateList, setTemplateList] = useState([]);
  const [templateSearchTerm, setTemplateSearchTerm] = useState('');
  const [isAllTemplateFetched, setIsAllTemplateFetched] = useState(false);
  const [isTemplateAllowClear, setIsTemplateAllowClear] = useState(false);
  const [isTemplateDropdownVisible, setIsTemplateDropdownVisible] = useState(
    false
  );
  const [isFetchMoreTemplateLoading, setIsFetchMoreTemplateLoading] = useState(
    false
  );
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);

  const [executeTemplate, { loading: isTemplateLoading }] = useLazyQuery(
    TEMPLATES,
    {
      onCompleted: (response) => {
        if (response?.templates?.templates?.length < LIMIT) {
          setIsAllTemplateFetched(true);
        }
        setIsTemplateSelected(false);
        setIsFetchMoreTemplateLoading(false);
        setTemplateList([...templateList, ...response?.templates?.templates]);
      },
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  useEffect(() => {
    if (!isTemplateDropdownVisible) {
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            search: templateSearchTerm,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
    setIsAllTemplateFetched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateDropdownVisible]);

  useEffect(() => {
    if (isTemplateSelected && isTemplateDropdownVisible && templateSearchTerm) {
      setTemplateSearchTerm('');
      setTemplateList([]);
      executeTemplate({
        variables: {
          filter: {
            skip: 0,
            limit: LIMIT,
            search: templateSearchTerm,
            projectEnvId: state?.projectEnvId,
            sortBy: 'createdAtDESC',
            type: 'EMAIL'
          }
        }
      });
    }
    setIsAllTemplateFetched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateSelected, isTemplateDropdownVisible, templateSearchTerm]);

  const handleTemplateScroll = (event) => {
    if (!isAllTemplateFetched && !isTemplateLoading) {
      const target = event?.target;
      const currentLength = templateList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreTemplateLoading(true);
        executeTemplate({
          variables: {
            filter: {
              skip: currentLength,
              limit: LIMIT,
              search: templateSearchTerm,
              projectEnvId: state?.projectEnvId,
              sortBy: 'createdAtDESC',
              type: 'EMAIL'
            }
          }
        });
      }
    }
  };

  const handleTemplateSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllTemplateFetched(false);
    setTemplateList([]);
    setTemplateSearchTerm(trimSearch);
    executeTemplate({
      variables: {
        filter: {
          skip: 0,
          limit: LIMIT,
          search: trimSearch,
          projectEnvId: state?.projectEnvId,
          sortBy: 'createdAtDESC',
          type: 'EMAIL'
        }
      }
    });
  };

  const handleTemplateScrollDebounce = debounce(handleTemplateScroll, 500);
  const handleSearchTemplateDebounce = debounce(handleTemplateSearch, 500);

  useEffect(() => {
    handleTemplateScrollDebounce?.cancel();
  }, [handleTemplateScrollDebounce]);

  useEffect(() => {
    handleSearchTemplateDebounce?.cancel();
  }, [handleSearchTemplateDebounce]);

  useEffect(() => {
    if (isTemplateLoading) {
      setIsTemplateAllowClear(false);
    } else {
      setIsTemplateAllowClear(true);
    }
  }, [isTemplateLoading]);

  return (
    <SelectComponent
      mode="multiple"
      showSearch
      loading={
        (isTemplateLoading && isTemplateDropdownVisible) ||
        isFetchMoreTemplateLoading
      }
      placeholder="Select Templates"
      optionFilterProp="children"
      allowClear={isTemplateAllowClear}
      onPopupScroll={handleTemplateScrollDebounce}
      onSearch={handleSearchTemplateDebounce}
      onDropdownVisibleChange={(visible) =>
        setIsTemplateDropdownVisible(visible)
      }
      filterOption={false}
      onChange={() => setIsTemplateAllowClear(true)}
      onSelect={() => setIsTemplateSelected(true)}
      maxTagCount={false}
      notFoundContent={
        !isFetchMoreTemplateLoading && isTemplateLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {templateList?.map((template) => (
        <Option key={template?.id} value={template?.id}>
          {template?.title} ({template?.key})
        </Option>
      ))}
    </SelectComponent>
  );
};

export default TemplateSelect;
