import { Modal } from 'antd';
import React from 'react';
import { CloseCircle } from '../../../assets/svg';

const PreviewModal = (props) => {
  const { isModalOpen, setIsModalOpen, isTemplateBody } = props;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      centered
      wrapClassName="edit-body-modal"
      visible={isModalOpen}
      onCancel={handleCancel}
      closeIcon={<CloseCircle />}
      footer={null}
      width={700}
    >
      <div>
        <div className="body-modal-title">Preview</div>
        <iframe title="Webview" className="preview" srcDoc={isTemplateBody} />
      </div>
    </Modal>
  );
};

export default PreviewModal;
