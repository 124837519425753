import React from 'react';
import SmsTabs from './components/SmsTabs';

const Sms = () => {
  return (
    <>
      <h1 className="title ml-30 mt-20">SMS</h1>
      <SmsTabs />
    </>
  );
};

export default Sms;
