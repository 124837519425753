import { Card, Tabs } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import '../members.less';
import InviteTeamMembersContainer from './InviteTeamMembersContainer';

const { TabPane } = Tabs;
const InviteTeamMembersList = () => {
  return (
    <>
      <h1 className="title ml-30 mt-20">{MODULES?.MEMBERS}</h1>
      <Card className="full-height-card ">
        <Tabs
          className="tabs no-border-top-left-radius without-sub-header-content"
          type="card"
        >
          <TabPane tab={MODULES?.MEMBERS}>
            <InviteTeamMembersContainer />
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default InviteTeamMembersList;
