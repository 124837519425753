import { useLazyQuery } from '@apollo/client';
import { Empty, Select } from 'antd';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { LIMIT, USERS_ROLE } from '../../../common/constants';
import SelectComponent from '../../../components/SelectComponent';
import { GET_PROJECT_MEMBERS } from '../graphql/Queries';

const { Option } = Select;

const MemberSelect = (props) => {
  const { id, projectEnvId, ...rest } = props;
  const [memberList, setMemberList] = useState([]);
  const [memberSearchTerm, setMemberSearchTerm] = useState('');
  const [isAllMemberFetched, setIsAllMemberFetched] = useState(false);
  const [isMemberAllowClear, setIsMemberAllowClear] = useState(false);
  const [isMemberDropdownVisible, setIsMemberDropdownVisible] = useState(false);
  const [isFetchMoreMemberLoading, setIsFetchMoreMemberLoading] = useState(
    false
  );

  const [getProjectMembers, { loading: isMemberLoading }] = useLazyQuery(
    GET_PROJECT_MEMBERS,
    {
      onCompleted: (response) => {
        if (response?.projectMembers?.projectMember?.length < LIMIT) {
          setIsAllMemberFetched(true);
        }
        setIsFetchMoreMemberLoading(false);
        setMemberList([
          ...memberList,
          ...response?.projectMembers?.projectMember
        ]);
      },
      fetchPolicy: 'network-only',
      onError() {}
    }
  );

  useEffect(() => {
    setMemberSearchTerm('');
    setMemberList([]);
    getProjectMembers({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: memberSearchTerm,
          sortBy: 'createdAtDESC'
        },
        id,
        projectEnvId
      }
    });
    setIsAllMemberFetched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberDropdownVisible]);

  const handleMemberScroll = (event) => {
    if (!isAllMemberFetched && !isMemberLoading) {
      const target = event?.target;
      const currentLength = memberList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreMemberLoading(true);
        getProjectMembers({
          variables: {
            filter: {
              limit: LIMIT,
              skip: currentLength,
              search: memberSearchTerm,
              sortBy: 'createdAtDESC'
            },
            id,
            projectEnvId
          }
        });
      }
    }
  };

  const handleMemberSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllMemberFetched(false);
    setMemberList([]);
    setMemberSearchTerm(trimSearch);
    getProjectMembers({
      variables: {
        filter: {
          limit: LIMIT,
          skip: 0,
          search: trimSearch,
          sortBy: 'createdAtDESC'
        },
        id,
        projectEnvId
      }
    });
  };

  const handleMemberScrollDebounce = debounce(handleMemberScroll, 500);
  const handleSearchMemberDebounce = debounce(handleMemberSearch, 500);

  useEffect(() => {
    handleMemberScrollDebounce?.cancel();
  }, [handleMemberScrollDebounce]);

  useEffect(() => {
    handleSearchMemberDebounce?.cancel();
  }, [handleSearchMemberDebounce]);

  useEffect(() => {
    if (isMemberLoading) {
      setIsMemberAllowClear(false);
    } else {
      setIsMemberAllowClear(true);
    }
  }, [isMemberLoading]);

  return (
    <SelectComponent
      showSearch
      loading={
        (isMemberLoading && isMemberDropdownVisible) || isFetchMoreMemberLoading
      }
      placeholder="Select Member"
      optionFilterProp="children"
      allowClear={isMemberAllowClear}
      onPopupScroll={handleMemberScrollDebounce}
      onSearch={handleSearchMemberDebounce}
      onDropdownVisibleChange={(visible) => setIsMemberDropdownVisible(visible)}
      filterOption={false}
      notFoundContent={
        !isFetchMoreMemberLoading && isMemberLoading ? (
          <span>Loading...</span>
        ) : (
          <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
    >
      {memberList
        ?.filter((member) => member?.permission !== USERS_ROLE?.OWNER)
        ?.map((member) => (
          <Option key={member?.id} value={member?.id}>
            {member?.email}
          </Option>
        ))}
    </SelectComponent>
  );
};

export default MemberSelect;
