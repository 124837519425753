import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Popconfirm, Tabs } from 'antd';
import { map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { NoProjectIcon } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import SearchComponent from '../../../components/SearchComponent';
import { DELETE_PROJECT } from '../graphql/Mutations';
import { PROJECT_LISTING } from '../graphql/Queries';

const { TabPane } = Tabs;
const ProjectList = ({ defaultActiveKey, isFirstTab = true }) => {
  const [projectListing, setProjectListing] = useState([]);
  const [isProjectEnd, setIsProjectEnd] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [projectSearch, setProjectSearch] = useState(false);
  const { dispatch } = useContext(AppContext);
  const [projectSkip, setProjectSkip] = useState(0);
  const history = useHistory();
  const [deleteProject] = useMutation(DELETE_PROJECT);
  const projectLimit = 10;

  const [executeProjectSearch, { loading }] = useLazyQuery(PROJECT_LISTING, {
    onCompleted: (response) => {
      if (response?.projects?.projects?.length < projectLimit) {
        setIsProjectEnd(true);
      }
      if (projectSearch) {
        setProjectListing([...response?.projects?.projects]);
        setProjectSearch(false);
      } else {
        setProjectListing([...projectListing, ...response?.projects?.projects]);
      }
      setProjectLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setProjectLoading(false);
    }
  });

  useEffect(() => {
    executeProjectSearch({
      variables: {
        filter: {
          skip: projectSkip,
          limit: projectLimit,
          search: '',
          sortBy: 'createdAtDESC'
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (projectSearchTerm) => {
    const trimSearch = projectSearchTerm?.trim();
    setProjectSearch(true);
    setProjectSkip(0);
    executeProjectSearch({
      variables: {
        filter: {
          skip: 0,
          limit: projectLimit,
          search: trimSearch,
          sortBy: 'createdAtDESC'
        }
      }
    });
    setIsProjectEnd(false);
  };

  const handleScroll = (event) => {
    if (!isProjectEnd && !projectLoading) {
      if (projectListing?.length > 0) {
        const target = event?.currentTarget;
        if (
          target?.scrollTop + target?.offsetHeight >=
          target?.scrollHeight - 5
        ) {
          setProjectLoading(true);
          const newSkip = projectSkip + projectLimit;
          setProjectSkip(newSkip);
          executeProjectSearch({
            variables: {
              filter: {
                skip: newSkip,
                limit: projectLimit,
                search: '',
                sortBy: 'createdAtDESC'
              }
            }
          });
        }
      }
    }
  };

  const deleteProjectFun = (data) => {
    setProjectLoading(true);
    deleteProject({
      variables: {
        id: data?.id
      }
    }).then(() => {
      setProjectListing([]);
      executeProjectSearch({
        variables: {
          filter: {
            skip: 0,
            limit: projectLimit,
            search: ''
          }
        }
      });
    });
  };

  return (
    <>
      <Card className="full-height-card landing-card">
        <Tabs
          className={
            isFirstTab
              ? 'tabs no-border-top-left-radius without-sub-header-content'
              : 'tabs border-top-left-radius without-sub-header-content'
          }
          type="card"
          onChange={(e) => {
            history?.push(`/${e}`);
          }}
          defaultActiveKey={defaultActiveKey || '/'}
        >
          <TabPane tab="Projects" key="projects">
            <div className="fill-width">
              <div className="d-flex justify-between">
                <div className="width-percent-40">
                  <SearchComponent
                    className="search-input"
                    name="Project"
                    getData={handleSearch}
                  />
                </div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="primary-button"
                  onClick={() =>
                    history?.push(`${ROUTES?.PROJECTS}${ROUTES?.ADDPROJECT}`)
                  }
                >
                  Create a project
                </Button>
              </div>
            </div>
            {!projectLoading && loading ? (
              <div className="mt-40 pt-15 text-center">
                <LoaderComponent setHeight="60" size="large" />
              </div>
            ) : (
              <>
                {projectListing?.length > 0 ? (
                  <div onScroll={handleScroll} className="project-wrapper">
                    {map(projectListing, (data) => {
                      return (
                        <Card key={data?.id}>
                          <div className="project-container">
                            <div className="project-header">
                              <div className="left-header">
                                <p className="project-name">{data?.name}</p>
                              </div>
                              <div className="right-header flex-wrap">
                                <Button
                                  className="view-project"
                                  onClick={() => {
                                    dispatch({
                                      type: 'SET_SHOWSIDEBAR',
                                      data: true
                                    });
                                    history?.push(
                                      `${ROUTES?.PROJECTS}/${data?.id}${ROUTES?.EMAIL}`
                                    );
                                  }}
                                >
                                  View project
                                </Button>
                                <Popconfirm
                                  title={`Are you sure you want to delete ${data?.name} project?`}
                                  onConfirm={() => deleteProjectFun(data)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button className="delete-project">
                                    Delete project
                                  </Button>
                                </Popconfirm>
                              </div>
                            </div>
                            <div className="project-body">
                              <div className="right-services">
                                <p>Created on 18th January by Joe V</p>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                    {projectLoading && (
                      <div className="mt-40 pt-15 text-center">
                        <LoaderComponent setHeight="5" size="large" />
                      </div>
                    )}
                  </div>
                ) : (
                  !projectLoading &&
                  !loading && (
                    <div className="no-project">
                      <div className="img-section">
                        <NoProjectIcon />
                      </div>
                      <div className="text-section d-flex align-center">
                        <span>
                          You have no new projects! Create a project and get
                          started!
                        </span>
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default ProjectList;
