import { gql } from '@apollo/client';

export const CREATE_TEAM = gql`
  mutation createTeam($data: CreateTeamData!) {
    createTeam(data: $data) {
      data {
        id
        name
      }
      message
      status
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($data: UpdateTeamData!) {
    updateTeam(data: $data) {
      data {
        id
        name
        logo
        createdBy
        teamMemberId
      }
      status
      message
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      message
      status
    }
  }
`;

export const INVITE_TEAM_MEMBER = gql`
  mutation inviteTeamMember($data: InviteTeamMemberData!) {
    inviteTeamMember(data: $data) {
      status
      message
    }
  }
`;

export const CREATE_PROJECT_MEMBER = gql`
  mutation createProjectMember($data: CreateProjectMemberData!) {
    createProjectMember(data: $data) {
      status
      message
    }
  }
`;

export const UPDATE_PROJECT_MEMBER = gql`
  mutation updateProjectMember($data: UpdatedProjectMemberData!) {
    updateProjectMember(data: $data) {
      data {
        id
        email
        permission
      }
      message
    }
  }
`;

export const DELETE_PROJECT_MEMBER = gql`
  mutation deleteProjectMember($id: ID!) {
    deleteProjectMember(id: $id) {
      status
      message
    }
  }
`;

export const TRANSFER_OWNERSHIP = gql`
  mutation TransferOwnership($input: TransferOwnershipInput!) {
    transferOwnership(input: $input) {
      message
    }
  }
`;
