import React from 'react';
import LandingTab from './components/LandingTab';
import './landing.less';

const Landing = () => {
  return (
    <>
      <LandingTab />
    </>
  );
};

export default Landing;
