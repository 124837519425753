import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation webLogin($data: loginInput!) {
    webLogin(data: $data) {
      authToken
      user {
        id
        firstName
        lastName
        email
        phoneNo
        profileImage {
          url
          key
          name
          extension
          contentType
        }
        isActive
      }
    }
  }
`;

export const SEND_OTP = gql`
  mutation sendOtp($email: String!) {
    sendOtp(email: $email) {
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation verifyOtp($data: loginData!) {
    verifyOtp(data: $data) {
      data {
        id
        firstName
        lastName
        email
        profileImage
        message
      }
      token
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotUserPassword($email: String) {
    forgotUserPassword(where: { email: $email }) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation updateUserPassword($password: String) {
    updateUserPassword(data: { password: $password }) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation isValidToken($resetToken: String) {
    isValidToken(data: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($token: String!) {
    refreshToken(token: $token) {
      token
    }
  }
`;
