import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { USERS_ROLE } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Nodata from '../../../components/Nodata';
import SearchComponent from '../../../components/SearchComponent';
import useDebounce from '../../../hooks/useDebounce';
import { PROJECT_ENVIRONMENT } from '../../projects/graphql/Queries';
import {
  CREATE_PROJECT_MEMBER,
  DELETE_PROJECT_MEMBER,
  TRANSFER_OWNERSHIP,
  UPDATE_PROJECT_MEMBER
} from '../graphql/Mutation';
import { GET_PROJECT_MEMBERS } from '../graphql/Queries';
import InviteMembersModal from './InviteMembersModal';
import InviteTeamMembersCard from './InviteTeamMembersCard';
import OwnershipModal from './OwnershipModal';

const MembersContainer = () => {
  const [form] = Form.useForm();
  const [ownershipForm] = Form.useForm();
  const { projectId } = useParams();
  const { state, getCurrentRole, dispatch } = useContext(AppContext);
  const userRole = getCurrentRole();
  const [inviteTeamMemberList, setInviteTeamMemberList] = useState([]);
  const [inviteTeamMemberSearchTerm, setInviteTeamMemberSearchTerm] = useState(
    ''
  );
  const [
    isAllInviteTeamMemberTeamsFetched,
    setIsAllInviteTeamMemberTeamsFetched
  ] = useState(false);
  const [
    isFetchMoreInviteTeamMemberLoading,
    setIsFetchMoreInviteTeamMemberLoading
  ] = useState(false);
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const [isOwnershipModalOpen, setIsOwnershipModalOpen] = useState(false);
  const [
    isInviteNewTeamMemberBtnLoading,
    setIsInviteNewTeamMemberBtnLoading
  ] = useState(false);
  const [isOwnershipBtnLoading, setIsOwnershipBtnLoading] = useState(false);
  const [isInviteTeamMemberLoading, setIsInviteTeamMemberLoading] = useState(
    true
  );
  const [inviteTeamMemberDetails, setInviteTeamMemberDetails] = useState();

  const limit = 10;

  const [executeInviteTeamMemberList] = useLazyQuery(GET_PROJECT_MEMBERS, {
    onCompleted: (response) => {
      if (response?.projectMembers?.projectMember?.length < limit) {
        setIsAllInviteTeamMemberTeamsFetched(true);
      }
      setIsInviteTeamMemberLoading(false);
      setInviteTeamMemberList([
        ...inviteTeamMemberList,
        ...response?.projectMembers?.projectMember
      ]);
      setIsFetchMoreInviteTeamMemberLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [executeProjectEnvironment] = useLazyQuery(PROJECT_ENVIRONMENT, {
    onCompleted: (res) => {
      dispatch({
        type: 'SET_CURRENT_ROLE',
        data: res?.projectEnvironment?.environments?.[0]?.permission
      });
    },
    fetchPolicy: 'network-only',
    onError() {}
  });

  const [executeCreateTeamMember] = useMutation(CREATE_PROJECT_MEMBER, {
    onError: () => {}
  });
  const [executeUpdateProjectMember] = useMutation(UPDATE_PROJECT_MEMBER, {
    onError: () => {}
  });
  const [executeDeleteTeamMember] = useMutation(DELETE_PROJECT_MEMBER, {
    onError: () => {}
  });
  const [executeTransferOwnership] = useMutation(TRANSFER_OWNERSHIP, {
    onError: () => {}
  });

  useEffect(() => {
    if (state?.projectEnvId && projectId) {
      setInviteTeamMemberList([]);
      setIsInviteTeamMemberLoading(true);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            limit,
            search: '',
            sortBy: 'createdAtDESC'
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, projectId]);

  const handleInviteTeamMemberScroll = (event) => {
    if (
      !isAllInviteTeamMemberTeamsFetched &&
      !isFetchMoreInviteTeamMemberLoading &&
      !isInviteTeamMemberLoading
    ) {
      const target = event?.target;
      const currentLength = inviteTeamMemberList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        setIsFetchMoreInviteTeamMemberLoading(true);
        executeInviteTeamMemberList({
          variables: {
            id: projectId,
            projectEnvId: state?.projectEnvId,
            filter: {
              skip: currentLength,
              limit,
              search: inviteTeamMemberSearchTerm,
              sortBy: 'createdAtDESC'
            }
          }
        });
      }
    }
  };

  const handleInviteTeamMemberScrollDebounce = useDebounce(
    handleInviteTeamMemberScroll,
    500,
    false
  );

  const handleReset = () => {
    form?.resetFields();
    setInviteTeamMemberDetails();
  };

  const inviteNewTeamMemberFun = async (values) => {
    setIsInviteNewTeamMemberBtnLoading(true);
    const response = await executeCreateTeamMember({
      variables: {
        data: {
          id: projectId,
          emails: values?.email,
          permission: values?.permission,
          projectEnvId: state?.projectEnvId
        }
      }
    });
    if (response?.data) {
      setIsInviteMemberModalOpen(false);
      setIsInviteTeamMemberLoading(true);
      setInviteTeamMemberList([]);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            limit,
            search: inviteTeamMemberSearchTerm,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setIsAllInviteTeamMemberTeamsFetched(false);
      setTimeout(handleReset, 500);
    }
    setIsInviteNewTeamMemberBtnLoading(false);
  };

  const updateProjectMemberFun = async (values) => {
    setIsInviteNewTeamMemberBtnLoading(true);
    const response = await executeUpdateProjectMember({
      variables: {
        data: {
          id: inviteTeamMemberDetails?.id,
          projectId,
          projectEnvId: state?.projectEnvId,
          permission: values?.permission
        }
      }
    });
    if (response?.data) {
      setIsInviteMemberModalOpen(false);
      setIsInviteTeamMemberLoading(true);
      setInviteTeamMemberList([]);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            limit,
            search: inviteTeamMemberSearchTerm,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setIsAllInviteTeamMemberTeamsFetched(false);
      setTimeout(handleReset, 500);
    }
    setIsInviteNewTeamMemberBtnLoading(false);
  };

  const ownershipFun = async (values) => {
    setIsOwnershipBtnLoading(true);
    const response = await executeTransferOwnership({
      variables: {
        input: {
          projectId: projectId,
          projectMemberId: values?.member,
          projectEnvId: state?.projectEnvId
        }
      }
    });
    if (response?.data) {
      setIsOwnershipModalOpen(false);
      const res = executeProjectEnvironment({
        variables: {
          projectId: projectId
        }
      });
      if (res?.data) {
        setIsInviteTeamMemberLoading(true);
        setInviteTeamMemberList([]);
        executeInviteTeamMemberList({
          variables: {
            id: projectId,
            projectEnvId: state?.projectEnvId,
            filter: {
              skip: 0,
              limit,
              search: inviteTeamMemberSearchTerm,
              sortBy: 'createdAtDESC'
            }
          }
        });
        setIsAllInviteTeamMemberTeamsFetched(false);
        ownershipForm?.resetFields();
      }
    }
    setIsOwnershipBtnLoading(false);
  };

  const deleteInviteTeamMemberFun = async (id) => {
    const response = await executeDeleteTeamMember({
      variables: {
        id: id
      }
    });
    if (response?.data) {
      setIsInviteTeamMemberLoading(true);
      setInviteTeamMemberList([]);
      executeInviteTeamMemberList({
        variables: {
          id: projectId,
          projectEnvId: state?.projectEnvId,
          filter: {
            skip: 0,
            limit,
            search: inviteTeamMemberSearchTerm,
            sortBy: 'createdAtDESC'
          }
        }
      });
      setIsAllInviteTeamMemberTeamsFetched(false);
    }
  };

  const handleInviteTeamMemberSearch = (searchTerm) => {
    const trimSearch = searchTerm?.trim();
    setIsAllInviteTeamMemberTeamsFetched(false);
    setIsInviteTeamMemberLoading(true);
    setInviteTeamMemberList([]);
    setInviteTeamMemberSearchTerm(trimSearch);
    executeInviteTeamMemberList({
      variables: {
        id: projectId,
        projectEnvId: state?.projectEnvId,
        filter: {
          skip: 0,
          limit,
          search: trimSearch,
          sortBy: 'createdAtDESC'
        }
      }
    });
  };

  return (
    <>
      <InviteMembersModal
        form={form}
        initialValues={inviteTeamMemberDetails}
        formTitle={inviteTeamMemberDetails ? 'Edit Members' : 'Add Members'}
        submitButton={inviteTeamMemberDetails ? 'Update' : 'Invite'}
        isModalOpen={isInviteMemberModalOpen}
        setIsModalOpen={setIsInviteMemberModalOpen}
        onFinish={
          inviteTeamMemberDetails
            ? updateProjectMemberFun
            : inviteNewTeamMemberFun
        }
        loadings={isInviteNewTeamMemberBtnLoading}
        handleReset={handleReset}
      />
      <OwnershipModal
        id={projectId}
        projectEnvId={state?.projectEnvId}
        form={ownershipForm}
        initialValues={inviteTeamMemberDetails}
        formTitle="Transfer Ownership"
        submitButton="Save"
        isModalOpen={isOwnershipModalOpen}
        setIsModalOpen={setIsOwnershipModalOpen}
        onFinish={ownershipFun}
        loadings={isOwnershipBtnLoading}
        handleReset={() => ownershipForm?.resetFields()}
      />
      <div className="fill-width">
        <div className="d-flex justify-between">
          <div className="width-percent-40">
            <SearchComponent
              query={inviteTeamMemberSearchTerm}
              setQuery={setInviteTeamMemberSearchTerm}
              className="search-input member-search-element"
              getData={handleInviteTeamMemberSearch}
            />
          </div>
          <div>
            {userRole === USERS_ROLE?.OWNER && (
              <Button
                type="primary"
                className="primary-button"
                onClick={() => setIsOwnershipModalOpen(true)}
              >
                Transfer Ownership
              </Button>
            )}
            {userRole === USERS_ROLE?.OWNER && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="primary-button ml-30"
                onClick={() => setIsInviteMemberModalOpen(true)}
              >
                Invite Member
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        onScroll={handleInviteTeamMemberScrollDebounce}
        className="member-wrapper"
      >
        {!isFetchMoreInviteTeamMemberLoading && isInviteTeamMemberLoading ? (
          <LoaderComponent setHeight="30" size="large" />
        ) : (
          <>
            {inviteTeamMemberList?.length > 0 && (
              <>
                {inviteTeamMemberList?.map((inviteTeamMember) => {
                  return (
                    <InviteTeamMembersCard
                      key={inviteTeamMember?.id}
                      inviteTeamMember={inviteTeamMember}
                      inviteTeamMemberDeleteHandler={deleteInviteTeamMemberFun}
                      setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
                      setInviteTeamMemberDetails={setInviteTeamMemberDetails}
                      userRole={userRole}
                    />
                  );
                })}
                {isFetchMoreInviteTeamMemberLoading && (
                  <LoaderComponent setHeight="5" />
                )}
              </>
            )}
            {!isFetchMoreInviteTeamMemberLoading &&
              !isInviteTeamMemberLoading &&
              inviteTeamMemberList?.length === 0 && (
                <div className="text-center analytics-illustration">
                  <Nodata />
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default MembersContainer;
