import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TEMPLATES = gql`
  query templates($filter: TemplateFilter!) {
    templates(filter: $filter) {
      count
      templates {
        id
        key
        title
        subject
        body
        design
      }
    }
  }
`;

export const GET_TEMPLATE = gql`
  query template($id: ID!) {
    template(id: $id) {
      id
      key
      title
      subject
      body
      design
    }
  }
`;
