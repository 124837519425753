import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddProject from './components/AddProject';
import EditProject from './components/EditProject';
import Projects from './Projects';
import ProjectsDashboard from './ProjectsDashboard';
import './projects.less';

const ProjectsWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES?.PROJECTS} component={Projects} />
      <Route
        path={`${ROUTES?.PROJECTS}${ROUTES?.ADDPROJECT}`}
        component={AddProject}
      />
      <Route
        path={`${ROUTES?.PROJECTS}/:projectId${ROUTES?.EDIT_PROJECT}`}
        component={EditProject}
      />
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId/:key`}
        component={ProjectsDashboard}
      />
      <Route
        exact
        path={`${ROUTES?.PROJECTS}/:projectId/:key/:id`}
        component={ProjectsDashboard}
      />
    </Switch>
  );
};

export default ProjectsWrapper;
