import { Button, Form, Modal, Space } from 'antd';
import React from 'react';
import { CloseCircle } from '../../assets/svg';

const DeleteENVModal = ({
  isModalOpen,
  setIsModalOpen,
  value,
  onFinish,
  setProjectENVDeleteData,
  submitButton,
  loadings,
  form,
  handleReset
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
    setProjectENVDeleteData();
    setTimeout(handleReset, 500);
  };

  return (
    <>
      <Modal
        centered
        wrapClassName="environment-modal delete-env-modal"
        visible={isModalOpen}
        onCancel={handleCancel}
        closeIcon={<CloseCircle />}
        footer={null}
        onOk={form?.submit}
        forceRender
        destroyOnClose
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger="onBlur"
        >
          <div className="add-container">
            <div className="d-flex flex-vertical justify-between">
              <p>
                Are you sure want to delete <span>{value?.environment}</span>{' '}
                ENV ?
              </p>
            </div>
            <Space size="middle" className="mt-20 ">
              <Button
                type="primary"
                htmlType="submit"
                className="primary-button"
                loading={loadings}
              >
                {submitButton}
              </Button>
              <Button
                className="secondary-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteENVModal;
