import { Button, Form, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { CloseCircle } from '../../../assets/svg';
import MemberSelect from './MemberSelect';

const OwnershipModal = ({
  id,
  projectEnvId,
  form,
  formTitle,
  submitButton,
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  handleReset
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    handleReset();
  };

  return (
    <Modal
      centered
      closeIcon={<CloseCircle />}
      wrapClassName="environment-modal channel-modal"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      onOk={form?.submit}
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
        onFinishFailed={() => setIsValidateTrigger(true)}
      >
        <div className="invite-member add-container">
          <div className="d-flex flex-horizontal justify-between">
            <p className="add-title">{formTitle}</p>
          </div>
          <div className="d-flex flex-vertical justify-between ">
            <Form.Item
              label="Member"
              name="member"
              rules={[
                {
                  required: true,
                  message: 'Please select member!'
                }
              ]}
            >
              <MemberSelect id={id} projectEnvId={projectEnvId} />
            </Form.Item>
          </div>
          <Space size="middle" className="mt-20 ">
            <Button
              type="primary"
              htmlType="submit"
              className="primary-button"
              loading={loadings}
            >
              {submitButton}
            </Button>
            <Button
              className="secondary-button"
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default OwnershipModal;
