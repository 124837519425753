import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Workbox } from 'workbox-window';
import { AppContextProvider } from './AppContext';
import Routes from './Routes';
import client from './apollo';
import './index.css';
import './styles/main.less';

const VERSION = require('../package.json').version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION', VERSION);

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

Sentry?.init({
  // dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

ReactDOM?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <Routes />
    </AppContextProvider>
  </ApolloProvider>,
  // eslint-disable-next-line no-undef
  document?.getElementById('root')
);
