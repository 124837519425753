import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROJECT_AUTH_DETAILS = gql`
  query projectEnvAuthDetails($id: ID!) {
    projectEnvAuthDetails(id: $id) {
      apiKey
      secretKey
    }
  }
`;
