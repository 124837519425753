import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_PROJECT_MEMBERS = gql`
  query projectMembers(
    $id: ID
    $projectEnvId: ID!
    $filter: ProjectMemberFilter!
  ) {
    projectMembers(id: $id, projectEnvId: $projectEnvId, filter: $filter) {
      projectMember {
        id
        email
        inviteStatus
        permission
      }
    }
  }
`;
