import React from 'react';

const CardComponent = ({ total, percentage, count, title }) => {
  return (
    <div className="analytics-card">
      <div className="analytics-card-title">{title}</div>
      {total || total === 0 ? (
        <div className="analytics-card-count">{total}</div>
      ) : (
        <>
          <div className="analytics-card-per">{percentage}%</div>
          <div className="analytics-card-small-count">{count}</div>
        </>
      )}
    </div>
  );
};

export default CardComponent;
