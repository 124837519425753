import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_ANALYTICS = gql`
  query getAnalytics($filter: GetMessageAnalyticsFilter) {
    getMessageAnalytics(filter: $filter) {
      data {
        FAILED {
          count
          label
        }
        READ {
          count
          label
        }
        SUCCESS {
          count
          label
        }
        UNREAD {
          count
          label
        }
        stats {
          failedEmailPercentage
          readEmailPercentage
          successEmailPercentage
          totalEmails
          totalFailedEmails
          totalReadEmails
          totalSuccessEmails
          totalUnreadEmails
          unreadEmailPercentage
        }
      }
      status
    }
  }
`;
