import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Landing from './Landing';

const LandingWrapper = () => {
  return (
    <Switch>
      <Route path={ROUTES?.LANDING} component={Landing} />
    </Switch>
  );
};

export default LandingWrapper;
