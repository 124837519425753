import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const CREATE_CHANNEL = gql`
  mutation createChannel($data: CreateChannelData!) {
    createChannel(data: $data) {
      message
    }
  }
`;

export const UPDATE_CHANNEL = gql`
  mutation updateChannel($id: ID!, $data: UpdateChannelData!) {
    updateChannel(id: $id, data: $data) {
      status
      message
    }
  }
`;

export const DELETE_CHANNEL = gql`
  mutation deleteChannel($id: ID!) {
    deleteChannel(id: $id) {
      status
      message
    }
  }
`;

export const SEND_MAIL_IN_CHANNEL = gql`
  mutation sendMailInChannel($channelId: ID!, $data: SendMailInChannelData!) {
    sendMailInChannel(channelId: $channelId, data: $data) {
      status
      message
    }
  }
`;

export const ADD_MEMBERS = gql`
  mutation addMemberInChannel($data: AddMemberData!, $channelId: ID!) {
    addMemberInChannel(data: $data, channelId: $channelId) {
      status
      message
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation removeMemberFromChannel($channelMemberId: ID!, $channelId: ID!) {
    removeMemberFromChannel(
      channelMemberId: $channelMemberId
      channelId: $channelId
    ) {
      status
      message
    }
  }
`;
