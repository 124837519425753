import { useLazyQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { CopyClipboard } from '../../../assets/svg';
import { REGEX, ROUTES } from '../../../common/constants';
import {
  capitalizeAndSplitFromCapitalLetter,
  handleCopy
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_PROJECT_AUTH_DETAILS } from '../graphql/Queries';

const AuthContent = ({ activeKey }) => {
  const { state } = useContext(AppContext);
  const [authDetails, setAuthDetails] = useState();
  const [isAuthDetailsLoading, setIsAuthDetailsLoading] = useState(false);

  const [executeSenderList] = useLazyQuery(GET_PROJECT_AUTH_DETAILS, {
    onCompleted: (response) => {
      setIsAuthDetailsLoading(false);
      setAuthDetails(response?.projectEnvAuthDetails);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsAuthDetailsLoading(false);
    }
  });

  useEffect(() => {
    if (state?.projectEnvId && activeKey === ROUTES?.AUTH_DETAILS) {
      setIsAuthDetailsLoading(true);
      executeSenderList({
        variables: {
          id: state?.projectEnvId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.projectEnvId, activeKey]);

  if (isAuthDetailsLoading) return <LoaderComponent setHeight={50} />;

  return (
    <>
      <Row gutter={[48]}>
        <Col span={6}>
          <p>App Name</p>
        </Col>
        <Col span={12}>
          <p>Test_App</p>
        </Col>
        <Col span={6} />
      </Row>

      {authDetails &&
        Object?.keys(authDetails)?.map((key) => {
          return (
            <div key={key}>
              {key !== '__typename' && (
                <Row
                  gutter={[48]}
                  className="mt-40 auth-keys"
                  justify="space-between"
                >
                  <Col span={6}>
                    <p>{capitalizeAndSplitFromCapitalLetter(key)}</p>
                  </Col>
                  {key !== 'secretKey' && (
                    <>
                      <Col span={12}>
                        <p>{authDetails?.[key]}</p>
                      </Col>
                      <Col className="auth-key-copy" span={6}>
                        <p>
                          <CopyClipboard
                            onClick={() => handleCopy(authDetails?.apiKey)}
                          />
                        </p>
                      </Col>
                    </>
                  )}
                  {key === 'secretKey' && (
                    <>
                      <Col span={12}>
                        <p>
                          {authDetails?.[key]?.replace(REGEX?.MASK_4DIGIT, '*')}
                        </p>
                      </Col>
                      <Col className="auth-key-copy" span={6}>
                        <p>
                          <CopyClipboard
                            onClick={() => handleCopy(authDetails?.secretKey)}
                          />
                        </p>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </div>
          );
        })}
    </>
  );
};

export default AuthContent;
